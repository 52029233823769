import { hexValue } from "ethers/lib/utils"

export default {
  1: {
    chainId: hexValue(1),
    chainName: "Ethereum Mainnet",
    nativeCurrency: {
      name: "Ether",
      symbol: "ETH",
      decimals: 18
    },
    rpcUrls: ["https://mainnet.infura.io/v3/8e4b2d3b0c0a4b0f9b0b3f2f2f2f2f2f"],
    blockExplorerUrls: ["https://etherscan.io/"]
  },
  137: {
    chainId: hexValue(137),
    chainName: "Polygon Mainnet",
    nativeCurrency: {
      name: "Matic",
      symbol: "MATIC",
      decimals: 18
    },
    rpcUrls: ["https://rpc-mainnet.maticvigil.com/"],
    blockExplorerUrls: ["https://polygonscan.com/"]
  },
  56: {
    chainId: hexValue(56),
    chainName: "Binance Smart Chain Mainnet",
    nativeCurrency: {
      name: "Binance Chain Native Token",
      symbol: "BNB",
      decimals: 18
    },
    rpcUrls: ["https://bsc-dataseed.binance.org/"],
    blockExplorerUrls: ["https://bscscan.com/"]
  }
}
