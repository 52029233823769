import { Spinner } from 'reactstrap'
const SpinnerComponent = () => {
  return (
    <div style={{ height: '100%' }} className="fallback-spinner app-loader">
      <Spinner />
    </div>
  )
}

export default SpinnerComponent
