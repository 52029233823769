// ** React Imports
import { Suspense, lazy } from "react"
import { createRoot } from "react-dom/client"
import { BrowserRouter } from "react-router-dom"

// ** Redux Imports
import { store } from "./redux/store"
import { Provider } from "react-redux"

// ** Intl, CASL & ThemeColors Context
import ability from "./configs/acl/ability"
import { AbilityContext } from "./utility/context/Can"
import { ThemeContext } from "./utility/context/ThemeColors"

// ** ThemeConfig

// ** Toast

// ** i18n
import "./configs/i18n"

// ** Spinner (Splash Screen)
import Spinner from "./@core/components/spinner/Fallback-spinner"

// ** Ripple Button
import "./@core/components/ripple-button"

// ** React Query
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"

// ** PrismJS
import "prismjs"
import "prismjs/themes/prism-tomorrow.css"
import "prismjs/components/prism-jsx.min"

// ** React Perfect Scrollbar
import "react-perfect-scrollbar/dist/css/styles.css"

// ** React Hot Toast Styles
import "@styles/react/libs/react-hot-toasts/react-hot-toasts.scss"

// ** Core styles
import "./@core/assets/fonts/feather/iconfont.css"
import "./@core/scss/core.scss"
import "./assets/scss/style.scss"

// ** Service Worker
import * as serviceWorker from "./serviceWorker"
import { Toaster } from "react-hot-toast"

import { Web3ReactProvider } from "@web3-react/core"
import { MetaMaskProvider } from "utility/hooks/useMetamask"
import { Web3Provider } from "@ethersproject/providers"
import { ContextProvider } from "utility/hooks/useContext"

// ** Lazy load app
const LazyApp = lazy(() => import("./App"))

const container = document.getElementById("root")
const root = createRoot(container)
const queryClient = new QueryClient()

function getLibrary(provider, connector) {
  return new Web3Provider(provider)
}

root.render(
  <Web3ReactProvider getLibrary={getLibrary}>
    <MetaMaskProvider>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <Provider store={store}>
            <ContextProvider>
              <Suspense fallback={<Spinner />}>
                <AbilityContext.Provider value={ability}>
                  <ThemeContext>
                    <LazyApp />
                    <Toaster />
                  </ThemeContext>
                </AbilityContext.Provider>
              </Suspense>
            </ContextProvider>
          </Provider>
        </BrowserRouter>
      </QueryClientProvider>
    </MetaMaskProvider>
  </Web3ReactProvider>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
