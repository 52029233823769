import React, { useState, useEffect, useMemo, useCallback } from "react"
import { injected } from "../wallet"
import { useWeb3React } from "@web3-react/core"
import networks from "utility/enum/networks"
import { hexValue } from "ethers/lib/utils"

export const MetaMaskContext = React.createContext(null)

export const MetaMaskProvider = ({ children }) => {
  const { activate, account, active, deactivate, library } = useWeb3React()

  const [isActive, setIsActive] = useState(false)
  const [shouldDisable, setShouldDisable] = useState(false) // Should disable connect button while connecting to MetaMask
  const [isLoading, setIsLoading] = useState(true)

  // Check when App is Connected or Disconnected to MetaMask
  const handleIsActive = useCallback(() => {
    console.log("App is connected with MetaMask ", active)
    setIsActive(active)
  }, [active])

  useEffect(() => {
    handleIsActive()
  }, [handleIsActive])

  // Connect to MetaMask wallet
  const connect = async () => {
    console.log("Connecting to MetaMask...")
    setShouldDisable(true)
    try {
      await activate(injected).then(() => {
        setShouldDisable(false)
      })
    } catch (error) {
      console.log("Error on connecting to Metamask: ", error)
    }
  }

  const switchChain = async (chainId) => {
    const chainIdHex = `0x${chainId.toString(16)}`
    try {
      await library.provider.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: chainIdHex }]
      })
    } catch (error) {
      if (!networks[chainId]) {
        console.error("No supported network with chainId", chainId)
      } else if (error.code && error.code === 4902) {
        try {
          await library.provider.request({
            method: "wallet_addEthereumChain",
            params: [networks[chainId]]
          })
        } catch (addError) {
          console.error("Error adding Network", addError)
        }
      } else {
        console.error("Error switching networks", error)
      }
    }
  }

  // Disconnect from Metamask wallet
  const disconnect = async () => {
    console.log("Disconnecting wallet from App...")
    try {
      await deactivate()
    } catch (error) {
      console.log("Error on disconnnecting to Metamask: ", error)
    }
  }

  const values = useMemo(
    () => ({
      isActive,
      account,
      isLoading,
      connect,
      disconnect,
      shouldDisable,
      switchChain
    }),
    [isActive, isLoading, shouldDisable, account]
  )

  return <MetaMaskContext.Provider value={values}>{children}</MetaMaskContext.Provider>
}

export default function useMetaMask() {
  const context = React.useContext(MetaMaskContext)

  if (context === undefined) {
    throw new Error("useMetaMask hook must be used with a MetaMaskProvider component")
  }

  return context
}
