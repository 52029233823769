import { PaymentLinkQRCodeModalInfos } from "interfaces/payment-link"
import { Dispatch, SetStateAction, createContext, useContext, useState } from "react"


export interface IContext {
    paymentLinkModalInfos: PaymentLinkQRCodeModalInfos,
    setPaymentLinkModalInfos: Dispatch<SetStateAction<PaymentLinkQRCodeModalInfos>>
}

const Context = createContext<IContext>(null)


export const ContextProvider = ({children}) => {
    const [paymentLinkModalInfos, setPaymentLinkModalInfos] = useState({
        isOpen: false,
        infos:{
            value: null,
            link: null
        }
    })

    return (
        <Context.Provider value={{paymentLinkModalInfos, setPaymentLinkModalInfos}}>
            {children}
        </Context.Provider>
    )
}

export const useGlobalContext = () => {
    const context = useContext(Context)
    if (!context) {
      throw new Error("useGlobalContext must be used within a StoreProvider")
    }
    return context
  }
  